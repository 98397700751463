
































































import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import BaseInput from "@/components/UI/BaseInput.vue";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router/composables";
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { useUserStore } from "@/store/useUserStore";

export default defineComponent({
  name: "Login",
  components: { BaseInput },
  setup() {
    const form = reactive<AuthRequestInterface>({
      login: "",
      password: ""
    });
    const errorMessage = ref<string>("");
    const isLoading = ref<boolean>(false);
    const showPass = ref<boolean>(false);
    const userStore = useUserStore();
    const router = useRouter();
    const v$ = useVuelidate(
      {
        login: {
          required,
          min: minLength(3)
        },
        password: {
          required,
          min: minLength(12)
        }
      },
      form
    );

    const login = async (): Promise<void> => {
      errorMessage.value = "";
      isLoading.value = true;
      if (v$.value.$invalid) {
        v$.value.$touch();
        return;
      }

      try {
        await userStore.login({
          email: form.login.trim(),
          password: form.password.trim()
        });
      } catch (e) {
        if (e.message) {
          errorMessage.value = e.message;
          return;
        }

        errorMessage.value = "Произошла ошибка, обратитесь в службу поддержки";
      }

      await router.push(
        form.login === "support@yandex.ru" ? "/register" : "/order-ftl"
      );
    };

    onMounted(() => {
      if (!userStore.updateTimer) return;

      clearTimeout(userStore.updateTimer);
    });

    return {
      form,
      errorMessage,
      isLoading,
      v$,
      showPass,
      login
    };
  }
});
